<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('mass_course_closure')" :isNewButton="false" :isColumns="false">
				</Header>
				<HeaderMobile :title="$t('mass_course_closure')" :isNewButton="false" :isColumns="false">
				</HeaderMobile>
			</template>
			<ValidationObserver ref="formValidate">
				<b-row>
					<b-col cols="12" md="4">
						<b-form-group :label="$t('period')">
							<ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
								<semesters-selectbox v-model="formData.semester_id"
									:validate-error="errors[0]"></semesters-selectbox>
							</ValidationProvider>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="4">
						<ValidationProvider name="type" rules="required" v-slot="{ errors }">
							<label>{{ $t('process_type') }}</label>
							<div class="border p-4 rounded-sm" :class="errors[0] ? 'border-danger' : ''">
								<b-form-group class="m-0">
									<b-form-radio-group id="radio-group-2" v-model="formData.type"
										name="radio-sub-component" stacked>
										<b-form-radio value="1">{{ $t('close_lessons_only') }}</b-form-radio>
										<b-form-radio value="2">{{ $t('lessons_only_and_delete_students') }}</b-form-radio>
										<b-form-radio value="3">{{ $t('lessons_only_and_delete_students_and_sms_send')
										}}</b-form-radio>
									</b-form-radio-group>
								</b-form-group>
							</div>
							<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
						</ValidationProvider>
					</b-col>
					<b-col cols="12" md="4">
						<label>{{ $t('excel_file') }}</label>
						<div class="border p-4 rounded-sm">
							<b-form-group :label="$t('excel_file')" class="mb-2">
								<ValidationProvider name="file" rules="required" v-slot="{ valid, errors }">
									<div class="d-flex custom-file-upload">
										<b-form-file v-model="formData.file" :placeholder="$t('select_file')"
											:drop-placeholder="$t('drop_file')" ref="fileInput"
											:state="errors[0] ? false : (valid ? true : null)"
											accept="image/xls, image/xlsx" />
										<b-button variant="outline-secondary"
											@click="$refs.fileInput.$el.childNodes[0].click();">
											<i class="ri-add-fill"></i>
										</b-button>
									</div>
									<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
								</ValidationProvider>
							</b-form-group>
							<b-form-group :label="$t('is_there_a_header')" class="mb-1">
								<ValidationProvider name="with_headings" rules="required" v-slot="{ errors }">
									<b-form-select v-model="formData.with_headings" :options="withHeadingOptions" />
									<b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
								</ValidationProvider>
							</b-form-group>
						</div>
					</b-col>
				</b-row>
				<div class="d-flex justify-content-center mt-2">
					<b-button variant="primary" @click="send" label="send" />
				</div>
			</ValidationObserver>
		</app-layout>
	</div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";

// Other
import { ValidationProvider, ValidationObserver } from "vee-validate"

// Services
import SectionService from "@/services/SectionService"

// Component
export default {
	components: {
		AppLayout,
		Header,
		HeaderMobile,
		SemestersSelectbox,
		ValidationProvider,
		ValidationObserver,
	},
	metaInfo() {
		return {
			title: this.$t('mass_course_closure')
		}
	},
	data() {
		return {
			formData: {
				semester_id: null,
				type: null,
				file: null,
				with_headings: 1
			},
			formProcess: false,
			withHeadingOptions: [
				{ value: 1, text: this.$t('yes') },
				{ value: 0, text: this.$t('no') }
			]
		}
	},
	methods: {
		async send() {
			if (this.checkPermission('section_close')) {
				const isValid = await this.$refs.formValidate.validate();
				if (isValid) {
					let formData = new FormData();
					formData.append('semester_id', this.formData.semester_id)
					formData.append('type', this.formData.type)
					formData.append('file', this.formData.file)
					formData.append('with_headings', this.formData.with_headings)
					return SectionService.close(formData)
						.then(response => {
							this.$toast.success(this.$t('api.' + response.data.message))
							this.$refs.formValidate.reset();
							this.formData = {
								semester_id: null,
								type: null,
								file: null,
								with_headings: 1
							}
						})
						.catch(error => {
							this.showErrors(error, this.$refs.formValidate)

						})
				}
			} else {
				this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
			}

		}
	}
}
</script>
